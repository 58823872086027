<template>
  <div class="wrapper">
    <el-button type="text"  icon="el-icon-back" @click="backHandle" style="font-size:16px;" >返回</el-button>
    <div class="title">
      <span class="icon"  v-if="info.level">{{info.level}}</span>
      <strong>{{paperName}}</strong>
    </div>
    <div class="msg">
      <el-row :gutter="20">
        <el-col :span="19">
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="item">
                <span class="icon icon1"></span>
                <div class="text">
                  <span><strong>{{classAvgScore}}</strong>分</span>
                  <p>班级均分</p>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <span class="icon icon2"></span>
                <div class="text">
                  <span><strong>{{classMax}}</strong>分</span>
                  <p>班级最高分</p>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <span class="icon icon3"></span>
                <div class="text">
                  <span><strong>{{classMin}}</strong>分</span>
                  <p>班级最低分</p>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <span class="icon icon4"></span>
                <div class="text">
                  <span><strong>{{fullScore}}</strong>分</span>
                  <p>满分</p>
                </div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="box" >
                <div class="item"  v-for="(item,index) in msgList" :key="index">
                  <span class="icon" :class="item.icon"></span>
                  <div class="text">
                    <span><strong>{{item.avgScore}}</strong>分</span>
                    <p>{{item.method}}（{{item.doneEvated}}/{{item.sumCount}}）</p>
                    <p>权重{{item.weight}}%</p>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="5">
          <div class="score">
            <strong>{{totalScore}}</strong>
            <p>当前得分<span>{{time}}</span></p>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="info">
      <el-row :gutter="0">
        <el-col :span="6">
          <div class="item">
            <span class="label">专业</span>
            <p>{{info.majorName}}</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="item">
            <span class="label">班级</span>
            <p>{{info.className}}</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="item">
            <span class="label">学号</span>
            <p>{{info.code}}</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="item">
            <span class="label">姓名</span>
            <p>{{info.realName}}</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-table
      :data="tableData"
      :span-method="objectSpanMethod"
      :header-cell-style="{ background: '#eff3ff', color: '#5799f4' }"
      border
      style="width: 100%">
      <el-table-column
        type="index"
        align="center"
        label="序号"
        width="60">
      </el-table-column>
      <el-table-column
        v-if="targetType >= 1"
        prop="target1.name"
        label="一级指标"
        :width="targetType>1?180:undefined">
      </el-table-column>
      <el-table-column
        v-if="targetType >= 2"
        prop="target2.name"
        label="二级指标"
        :width="targetType>2?180:undefined">
      </el-table-column>
      <el-table-column
        v-if="targetType == 3"
        prop="target3.name"
        label="三级指标">
      </el-table-column>
      <el-table-column
        align="center"
        label="评价方式">
        <template v-for="(item,index) in this.weightList" >
          <el-table-column
            :key="index"
            width="110"
            align="center">
            <template slot="header">
              {{item.method}}（{{item.weight}}%）
            </template>
            <template slot-scope="scope">
              <strong>{{ scope.row.weightList[index].score  }}</strong>
            </template>
          </el-table-column>
        </template>
      </el-table-column>
    </el-table>
    <div class="total-td">
      当前得分：<strong>{{totalScore}}</strong>
      <template v-if="info.level">
        等级：<strong>{{info.level}}</strong>（{{info.description}}）
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      paperId:'',
      paperName:'',
      classAvgScore:'',
      fullScore:'',
      classMax:'',
      classMin:'',
      totalScore:'',
      time:'',
      msgList:[],
      tableData:[],
      targetType:'',
      weightList:'',
      info:{
        majorName:'',
        className:'',
        code:'',
        realName:'',
        totalScore:'',
        level:'',
        description:'',
      },
    }
  },
  mounted(){
    this.getMsg()
    this.getTableData()
  },
  methods: {
    backHandle(){
      this.$router.go(-1)
    },
    getMsg(){
      this.paperName = this.$route.query.paperName
      this.classAvgScore = this.$route.query.classAvgScore
      this.fullScore = this.$route.query.fullScore
      this.$get('evas/paper/student/statics/getStaticsDetailByPaperId',{
        paperId:this.$route.query.id
      }).then(res=>{
        if(res.success){
          this.classMax = res.result.classMaxScore
          this.classMin = res.result.classMixScore
          this.totalScore = res.result.currentScore
          this.time = res.result.evasTime
          this.msgList = res.result.list
          this.msgList.forEach((item)=>{
            switch (item.method) {
              case '自评':
                item['icon'] = 'icon5'
                break;
              case '互评':
                item['icon'] = 'icon6'
                break;
              case '班评':
                item['icon'] = 'icon7'
                break;
              case '师评':
                item['icon'] = 'icon8'
                break;
              default:
                break;
            }
          })
        }
      })
    },
    getTableData(){
      this.$get('evas/paper/student/statics/staticWeightByTarget',{
        paperId:this.$route.query.id
      }).then(res=>{
        if(res.success){
          this.targetType = res.result.targetType
          this.weightList = res.result.weightList
          this.tableData = res.result.resultList
          this.info = {
            majorName:res.result.majorName,
            className:res.result.className,
            code:res.result.code,
            realName:res.result.realName,
            totalScore:res.result.totalScore,
            level:res.result.level,
            description:res.result.description
          }
          this.tableData.forEach(item=>{
            if(this.targetType == 1){
              item.weightList = item.target1.weightList
            }
            if(this.targetType == 2){
              item.weightList = item.target2.weightList
            }
            if(this.targetType == 3){
              item.weightList = item.target3.weightList
            }
          })
        }
      })
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }){
      if(columnIndex == 1){
        return {rowspan:row.target1.rowSpan,colspan: row.target1.colSpan}
      }
      if(this.targetType >= 2){
        if(columnIndex == 2){
          return {rowspan:row.target2.rowSpan,colspan: row.target2.colSpan}
        }
      }
      if(this.targetType == 3){
        if(columnIndex == 3){
          return {rowspan:row.target3.rowSpan,colspan: row.target3.colSpan}
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 800px;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.title{
  text-align: center;
  position: relative;
  .icon{
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 80px;
    background: url(~@/assets/img/base/level.png) no-repeat center center;
    background-size: 100% 100%;
    text-align: center;
    font-size: 16px;
    color: #307ef4;
    line-height: 80px;
  }
  strong{
    font-size: 26px;
    padding: 0 90px;
    margin: 30px 0;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    &::after{
      content: '';
      display: block;
      width: 48px;
      height: 27px;
      background: url(~@/assets/img/base/title_left.png) no-repeat center center;
      background-size: 100% 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &::before{
      content: '';
      display: block;
      width: 48px;
      height: 27px;
      background: url(~@/assets/img/base/title_right.png) no-repeat center center;
      background-size: 100% 100%;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.msg{
  margin-bottom: 20px;
  padding: 20px;
  padding-top: 0;
  box-sizing: border-box;
  border-radius: 10px;
  border: 2px solid #f6f6fc;
  .item{
    height: 114px;
    background: #f2f6ff;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    .icon{
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #f9f9fd;
      text-align: center;
      box-shadow: 0 0 15px 0 rgba(0,0,0,0.05);
      margin-right: 5px;
    }
    .icon1{
      background: url(~@/assets/img/base/icon1.png) no-repeat center center,#f9f9fd;
    }
    .icon2{
      background: url(~@/assets/img/base/icon2.png) no-repeat center center,#f9f9fd;
    }
    .icon3{
      background: url(~@/assets/img/base/icon3.png) no-repeat center center,#f9f9fd;
    }
    .icon4{
      background: url(~@/assets/img/base/icon8.png) no-repeat center center,#f9f9fd;
    }
    .icon5{
      background: url(~@/assets/img/base/icon4.png) no-repeat center center,#f9f9fd;
    }
    .icon6{
      background: url(~@/assets/img/base/icon5.png) no-repeat center center,#f9f9fd;
    }
    .icon7{
      background: url(~@/assets/img/base/icon7.png) no-repeat center center,#f9f9fd;
    }
    .icon8{
      background: url(~@/assets/img/base/icon6.png) no-repeat center center,#f9f9fd;
    }
    .text{
      // width: 0;
      // flex: 1;
      text-align: center;
      span{
        color: #307ef4;
        margin-bottom: 10px;
        strong{
          font-size: 24px;
        }
      }
      p{
        font-size: 16px;
        color: #333;
        text-align: center;
      }
    }
  }
  .box{
    display: flex;
    margin: 20px -10px 0;
    .item{
      flex: 1;
      margin: 0 10px;
    }
  }
  .score{
    height: 248px;
    background: #f9f9fd;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    strong{
      display: block;
      text-align: center;
      line-height: 94px;
      width: 150px;
      height: 94px;
      font-size: 32px;
      color: #ff4800;
      background: url(~@/assets/img/base/badge.png) no-repeat center center;
      background-size: 100% 100%;
      margin: 0 auto;
    }
    p{
      font-size: 16px;
      color: #333;
      text-align: center;
      margin-top: 5px;
      span{
        display: block;
        font-size: 13px;
        color: #307ef4;
        margin-top: 10px;
      }
    }
  }
}
.info{
  font-size: 16px;
  margin-bottom: 15px;
  .item{
    border: 1px solid #EBEEF5;
    display: flex;
    margin-left: -1px;
    .label{
      width: 90px;
      border-right: 1px solid #EBEEF5;
      text-align: center;
      padding: 10px 5px;
      box-sizing: border-box;
      background: #eff3ff;
    }
    p{
      padding:10px 5px;
      box-sizing: border-box;
    }
  }
}
.total-td{
  border: 1px solid #EBEEF5;
  margin-top: -1px;
  padding: 10px 5px;
  text-align: center;
  font-size: 16px;
  strong{
    color: #5799f4;
  }
}
</style>
